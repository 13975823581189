<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="name"
          prepend-inner-icon="mdi-form-textbox"
          :label="$t('alarms.fields.name')"
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="message"
          prepend-inner-icon="mdi-form-textbox"
          :label="$t('alarms.fields.message')"
        />
      </v-col>

      <v-col cols="12">
        <v-select
          :label="$t('alarms.fields.type')"
          prepend-inner-icon="mdi-chart-line-variant"
          :items="types"
          v-model="type"
          item-text="name"
          item-value="value"
        />
      </v-col>

      <v-col cols="12" v-if="type == 0">
        <v-slider
          v-model="levelLimit"
          :label="$t('alarms.fields.levelLimit')"
          min="0"
          max="100"
          thumb-label="always"
        >
          <template v-slot:thumb-label>{{ levelLimit }} %</template>
        </v-slider>
      </v-col>

      <v-col cols="12" v-if="type == 2">
        <v-text-field
          v-model="changeLimit"
          :label="$t('alarms.fields.changeLimit')"
          type="number"
          prepend-inner-icon="mdi-counter"
        />
      </v-col>

      <v-col cols="12" v-if="type == 4">
        <v-text-field
          v-model="changeLimit"
          :label="$t('alarms.fields.hourLimit')"
          type="number"
          prepend-inner-icon="mdi-counter"
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="cooldownMinutes"
          :label="$t('alarms.fields.cooldownMinutes')"
          type="number"
          prepend-inner-icon="mdi-counter"
          :hint="$t('alarms.hints.cooldownMinutes')"
          persistent-hint
        />
      </v-col>

      <v-col cols="12">
        <contact-selector v-model="contacts" />
      </v-col>

      <v-col cols="12">
        <silo-selector v-model="silos" />
      </v-col>

      <v-col cols="12">
        <v-spacer />
        <v-btn text color="primary" @click="submit">
          {{ $t("common.save") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ContactSelector from "../contact/ContactSelector.vue";
import SiloSelector from "../silos/SiloSelector.vue";

export default {
  components: {
    ContactSelector,
    SiloSelector,
  },

  props: {
    edit: {
      type: Boolean,
      default: false,
    },

    alarm: {
      type: Object || null,
      default: null,
    },
  },

  data() {
    return {
      type: 0,
      name: "",
      message: "",
      levelLimit: 0,
      changeLimit: 0,
      cooldownMinutes: 60,
      contacts: [],
      silos: [],

      types: [
        { name: this.$t("alarms.types.0"), value: 0 },
        { name: this.$t("alarms.types.1"), value: 1 },
        { name: this.$t("alarms.types.2"), value: 2 },
        { name: this.$t("alarms.types.3"), value: 3 },
        { name: this.$t("alarms.types.4"), value: 4 },
      ],
    };
  },

  methods: {
    ...mapActions("alarms", ["createAlarm", "updateAlarm"]),

    async submit() {
      let payload = {
        type: this.type,
        name: this.name,
        message: this.message,
        levelLimit: this.levelLimit,
        changeLimit: this.changeLimit,
        cooldownMinutes: this.cooldownMinutes,
        contacts: this.contacts,
        silos: this.silos,
      };
      var res = false;

      if (!this.edit) res = await this.createAlarm(payload);
      else
        res = await this.updateAlarm({ alarmId: this.alarm.alarmId, payload });

      if (res) this.$router.push("/alarms");
    },

    setAlarmFromProp() {
      if (this.alarm == null) return;

      this.name = this.alarm.name;
      this.message = this.alarm.message;
      this.levelLimit = this.alarm.levelLimit;
      this.changeLimit = this.alarm.changeLimit;
      this.cooldownMinutes = this.alarm.cooldownMinutes;
      this.contacts = this.alarm.contacts;
      this.silos = this.alarm.silos;
      this.type = this.alarm.type;
    },
  },

  created() {
    this.setAlarmFromProp();
  },

  watch: {
    // Update the current form with the alarm from the prop information
    alarm() {
      this.setAlarmFromProp();
    },

    type(val) {
      if (val == 3) this.cooldownMinutes = 60 * 12;
    },
  },
};
</script>
