<template>
  <v-container fluid>
    <v-row>
      <v-col cols="9">
        <v-text-field
          v-model="searchTerm"
          prepend-icon="mdi-magnify"
          :label="$t('common.search')"
          @keyup="() => filterSilos()"
        />
      </v-col>
      <v-col>
        <v-btn @click="selectAll">{{ $t("common.selectAll") }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <div class="silo-chip-container">
        <div class="silo-chip" v-for="silo in filteredSilos" :key="silo.siloId">
          <v-row>
            <v-col cols="2">
              <v-checkbox
                v-model="silos"
                :value="silo.siloId"
                @click="select(silo.siloId)"
              />
            </v-col>

            <v-col cols="10">
              <div class="silo-chip-info-container">
                <span class="subtitle-1">{{ silo.name }}</span>
                <div class="meta-container">
                  <meta-display :silo="silo" :small="true" />
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MetaDisplay from "../silos/MetaDisplay.vue";

export default {
  name: "SiloSelector",

  components: {
    MetaDisplay,
  },

  props: {
    propsSilos: {
      type: Array,
      default: () => [],
    },
  },

  model: {
    prop: "propsSilos",
  },

  data() {
    return {
      searchTerm: "",
      filteredSilos: [],
    };
  },

  methods: {
    ...mapActions("silos", ["getSilos"]),

    isChecked(siloId) {
      return this.silos.includes(siloId);
    },

    select(siloId) {
      let idx = this.silos.findIndex((d) => d == siloId);
      if (idx == -1) this.silos.push(siloId);
      else this.silos.splice(idx, 1);
    },

    selectAll() {
      if (this.filteredSilos.length == this.silos.length) this.silos = [];
      else if (this.filteredSilos.length > 0)
        this.silos = this.filteredSilos.map((d) => d.siloId);
    },

    filterSilos() {
      if (this.searchTerm == "") this.filteredSilos = this.storeSilos;

      let st = this.searchTerm.toLowerCase();
      this.filteredSilos = this.storeSilos.filter(
        (d) =>
          (d.name && d.name.toLowerCase().search(st) != -1) ||
          (d.identifier && d.identifier.toLowerCase().search(st) != -1) ||
          (d.metas &&
            d.metas.length > 0 &&
            d.metas.filter(
              (m) =>
                (m.key && m.key.toLowerCase().search(st) != -1) ||
                (m.value && m.value.toLowerCase().search(st) != -1) ||
                (m.value &&
                  m.key &&
                  (m.key + ": " + m.value).toLowerCase().search(st) != -1)
            ).length > 0)
      );
    },
  },

  computed: {
    ...mapState("silos", { storeSilos: "silos" }),

    silos: {
      get() {
        return this.propsSilos;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  async created() {
    await this.getSilos();
    this.filterSilos();
  },
};
</script>

<style lang="sass">
.silo-chip-container
  max-height: 500px
  overflow-y: scroll
  display: flex
  flex-direction: row
  flex-wrap: wrap

  .silo-chip
    width: 250px
    padding: .5rem
    margin: .2rem
    border-radius: 5px
    border: 1px solid black
    box-shadow: 1px 1px 4px 0px

.meta-container
  height: 100px
</style>