<template>
  <!-- 1. Ability to add new meta
  2. Add edit mode to remove meta-data
  3. Display all the tags from the silo -->
  <v-card flat style="height: 100%; min-height: 35px; overflow-y: scroll">
    <v-card-title v-if="!small">
      Metas <v-spacer /> <v-switch label="Edit" v-model="editMode" />
    </v-card-title>
    <v-card-text v-if="editMode && !small">
      <!-- <v-container fluid class="ma-0"></v-container> -->
      <v-row>
        <v-col md="5" sm="12">
          <v-text-field
            prepend-icon="mdi-key"
            label="Key"
            v-model="key"
          ></v-text-field>
        </v-col>
        <v-col md="5" sm="12">
          <v-text-field
            prepend-icon="mdi-format-list-numbered"
            label="Value"
            v-model="keyValue"
          ></v-text-field>
        </v-col>
        <v-col md="2" sm="12" class="d-flex justify-center align-center">
          <v-btn color="primary" icon @click="storeMeta">
            <v-icon>mdi-plus</v-icon> Spara
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>

    <div :class="small ? 'small-chip-container' : 'card-container-max-height'">
      <v-chip
        class="ma-2"
        color="blue darken-4"
        label
        text-color="white"
        v-for="(meta, i) in silo.metas"
        :key="i"
        :close="editMode"
        @click:close="activateRemove(meta.siloMetaId)"
        :x-small="small"
      >
        <v-icon left> mdi-label </v-icon>
        {{ meta.key + ": " + meta.value }}
      </v-chip>
    </div>

    <delete-dialog
      :dialog="removeDialog"
      v-on:cancel-dialog="removeDialog = false"
      v-on:confirm-delete="removeMetaLocal"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import DeleteDialog from "../common/DeleteDialog.vue";

export default {
  name: "MetaDisplay",

  components: { DeleteDialog },

  props: {
    silo: {
      type: Object,
      default: function () {
        return {};
      },
    },

    small: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      key: "",
      keyValue: "",
      editMode: false,
      removeDialog: false,
      removeId: "",
    };
  },

  methods: {
    ...mapActions("silos", ["removeMeta", "createMeta"]),

    activateRemove(id) {
      this.removeId = id;
      this.removeDialog = true;
    },

    async removeMetaLocal() {
      await this.removeMeta(this.removeId);
      var idx = this.silo.metas.findIndex((d) => d.siloMetaId == this.removeId);
      if (idx != -1) this.silo.metas.splice(idx, 1);
      this.removeDialog = false;
    },

    async storeMeta() {
      if (this.key != "" && this.keyValue != "") {
        await this.createMeta({
          siloId: this.silo.siloId,
          payload: { key: this.key, value: this.keyValue },
        });

        this.silo.metas.push({ key: this.key, value: this.keyValue });

        this.key = "";
        this.keyValue = "";
      }
    },
  },

  created() {},
};
</script>

<style lang="sass">
.card-container-max-height
  max-height: 200px
  height: 250px
  overflow-y: scroll

.small-chip-container
  display: flex
  overflow-y: hidden
  overflow-x: hidden
  flex-direction: column
</style>