<template>
  <v-container fluid>
    <v-row>
      <v-col cols="11" sm="11">
        <v-autocomplete
          v-model="contacts"
          prepend-inner-icon="mdi-account-box"
          :items="calculatedContacts"
          :label="$t('alarms.fields.contacts')"
          return-object
          multiple
          item-text="email"
        >
          <template v-slot:label="item">
            {{ item.email }} - {{ item.phoneNumber }}
          </template>
        </v-autocomplete>
      </v-col>

      <v-col class="d-flex align-center justify-center" sm="1">
        <v-btn icon @click="createDialog = true" color="primary" outlined>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="createDialog">
      <v-card>
        <v-card-title>
          {{ $t("contacts.createTitle") }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="email"
            :label="$t('contacts.fields.email')"
            prepend-icon="mdi-at"
          />
          <phonenumber-input v-model="phoneNumber" />
        </v-card-text>

        <v-card-actions class="d-flex">
          <v-spacer />
          <v-btn @click="createDialog = false" text>
            {{ $t("common.close") }}
          </v-btn>
          <v-btn @click="addContact" text color="primary"
            >{{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PhonenumberInput from "../common/PhonenumberInput.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "ContactSelector",

  components: {
    PhonenumberInput,
  },

  props: {
    selectedContacts: {
      type: Array,
      default: () => [],
    },
  },

  model: {
    prop: "selectedContacts",
  },

  data() {
    return {
      createDialog: false,
      email: "",
      phoneNumber: "",
      errorMessage: "",
      calculatedContacts: [],
    };
  },

  methods: {
    ...mapActions("contacts", ["getContacts"]),

    addContact() {
      if (this.email == "") {
        this.errorMessage = this.$t("contacts.emailRequired");
        return;
      }

      let c = {
        email: this.email,
        phoneNumber: this.phoneNumber,
        useUser: false,
      };

      this.calculatedContacts.push(c);
      this.contacts.push(c);

      this.errorMessage = "";
      this.createDialog = false;
    },
  },

  computed: {
    ...mapState("contacts", { storeContacts: "contacts" }),

    contacts: {
      get() {
        return this.selectedContacts;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  async created() {
    await this.getContacts();

    if (this.contacts)
      for (var cntct of this.contacts.filter((e) => e.useUser)) {
        var contact = this.storeContacts.find((d) => d.userId == cntct.userId);
        if (contact) {
          this.contacts[
            this.contacts.findIndex((d) => d.userId == cntct.userId)
          ] = contact;
        }
      }

    this.calculatedContacts = this.storeContacts;
  },
};
</script>